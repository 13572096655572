import get from 'lodash/get';

export const useFormikField = (formik, path) => {
  return {
    path,
    value: get(formik, `values.${path}`),
    touched: get(formik, `touched.${path}`),
    error: get(formik, `errors.${path}`),
    setValue: (value) => {
      const oldValue = get(formik, `values.${path}`);
      (value !== oldValue) && formik.setFieldValue(path, value)
    },
  }
}

import React from 'react';
import { Divider } from 'antd';
import { useStateContext } from '../context/state';
import get from 'lodash/get';
import '../assets/scss/Products.scss';
import { Link } from 'react-scroll';
import { useStoreHistory } from '../hooks/router';
import defaultProductPic from '../assets/img/defaultProductPic.png';

const Products = () => {
  const history = useStoreHistory();
  const [state, dispatch] = useStateContext();
  const defaultImage = defaultProductPic;

  const prods = get(state, "prods", []);

  const seleccionar = (selectedId) => {
    history.push(`/add-product/${selectedId}`);
  }

  const hash = get(state, 'hash');
  // console.log(hash);
  const categories = get(state, 'categories');

  // const doSomething = (category) => {
  // console.log("hola");
  // alert("hola");
  // scroller.scrollTo("bar-Postre")
  // console.log(category)
  // const el = document.getElementById("bar-"+category);
  // el.focus();
  // console.log(el.hasFocus);
  // }

  return (
    <div>
        <div className="categories">
          {/* <div onClick={()=>doSomething()}>hola</div> */}
          {categories.map((category) => {
            return (
              <div style={{ fontSize: '15px' }} key={category.id}>
                <Link
                  className="category unselectable"
                  activeClass="colored"
                  to={category.name}
                  spy={true}
                  smooth={true}
                  hashSpy={true}
                  offset={-30}
                  isDynamic={true}
                >
                  {category.name}
                </Link>
              </div>
            );
          })}
        </div>

      <ul className={"allProducts"}>
        {categories.map((category) => {
          return (
            <div key={category.id} name={category.name}>
              <div key={category.id} className="category">{category.name}</div>
              <Divider />
              {prods.map((prod) => {
                return (
                  prod.category_id === category.id ? (
                    <div key={prod.id}>
                      <div className="product" key={prod.id}>
                        <div className="unselectable">
                          <img
                            src={get(prod, 'metadata.src') !== undefined ? get(prod, 'metadata.src') : defaultImage}
                            alt={prod.alt}
                          />
                        </div>
                        <div className={"info"}>
                          <div className="name">{prod.name}</div>
                          <div className="description">{prod.description}</div>
                          <div className="footer">
                            <div style={{ float: 'left' }}>${prod.price ? prod.price.toFixed(2) : null}</div>
                            <div style={{ float: 'right', fontSize: '16px' }}><button className="boton pointable unselectable" onClick={() => seleccionar(prod.id)}> Agregar</button></div>
                          </div>
                        </div>
                      </div>
                      <Divider />
                    </div>
                  ) : null
                );
              })}

            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default Products;
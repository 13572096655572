import React, { Suspense, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './assets/scss/App.scss';
import { Switch, Route, useParams, useLocation } from 'react-router-dom';
import routes from './routes';
import { ContextProvider, useStateContext } from './context/state';
import api from './services/api';
import './assets/scss/antd-override.scss';
import './assets/scss/app-themes.scss';
import Gif from './assets/img/gifcaritas.gif';
import { useRouteChildren } from './hooks/router';
import get from 'lodash/get';
import qs from 'query-string';


// const GAevents = {
//   Load: {
//     hitType:'event',
//     category: 'ecommerce',
//     action: 'load',
//     label: 'test',
//   },
// };

// function checkGALoaded() {
  
//   if (typeof ga === 'function') {
//     console.log("GA is a function");
//     const gaObjectName = window.GoogleAnalyticsObject || 'ga';
//     const ga = window[gaObjectName];
//     const query = qs.parse(window.location.search);
//     console.log(query.userid);
//     if(query.userID){
//       window.ga('set', 'userId', query.userID);     
//     }
//     ga('send', {
//       hitType: 'event',
//       eventCategory: 'Videos',
//       eventAction: 'play',
//       eventLabel: 'Fall Campaign'
//     });

//     // // window.ga

//     ga(function() {
//       // Logs an array of all tracker objects.
//       console.log(window.ga.getAll());
//     });

//     ga(function(tracker) {
//       console.log("ENTRE");
//       console.log(window.ga.getAll()[0]);
//       var clientId = tracker.get('clientId');
//       console.log(clientId);
//     });

//   }
//   else {
//     console.log("GA is not a function");
//     setTimeout(checkGALoaded, 500);
//   }

// }

const AppWithContext = () => {
  const [state, dispatch] = useStateContext();

  const params = useParams();

  const routeChildren = useRouteChildren(routes);

  const [theme, setTheme] = useState({});

  const NoMatch = () => <div>Página no encontrado o no tiene permisos</div>;
  const LoadingScreen = () => {
    return (
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100vw',
          height: '100vh',

        }}
      >
        <img style={{ height: '75px' }} src={Gif} alt="Loading..." />
      </div>
    )
  };


  useEffect(() => {

    //GET MAIN DATA

    (async () => {

      api.storeKey = params.store;
      const response = await api.getMainData();

      if (!response) throw new Error('empty data');

      const store = response.store;
      const products = response.products;
      const categories = response.categories;

      const theme = get(response, 'store.theme',{});
      theme.templates = get(theme,'templates',[]);
      setTheme(theme);

      // return;
      // console.log(response);
      // console.log(store);
      // console.log(categories);

      // try {
      //   const cart = JSON.parse(localStorage.getItem('cart'));
      //   dispatch({
      //     type: 'cart.set',
      //     payload: cart,   
      //   });
      // } catch (e) {

      // }

      dispatch({
        type: 'store.update',
        payload: store,
      })

      dispatch({
        type: 'prods.update',
        payload: products,
      })

      dispatch({
        type: 'categories.update',
        payload: categories,
      })
    })();

    (async () => {
      const data = await api.getCart();
      // console.log(data);
      dispatch({
        type: 'hash.update',
        payload: data['hash'],
      });
    })();

    // GTAG:

    // checkGALoaded();



  }, []);

  return (
    <div className="app-container"

      style={theme}>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routeChildren.map((route) => (
            <Route key={route} {...route} />
          ))}
          <Route component={() => <NoMatch />} />
        </Switch>
      </Suspense>
    </div>

  );
}

function App() {
  return (
    <ContextProvider>
      <AppWithContext />
    </ContextProvider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { useStateContext } from '../context/state';
import get from 'lodash/get';
import '../assets/scss/ViewOrder.scss';
import '../assets/scss/ViewCheckout.scss';
import { Row, Col, Skeleton } from 'antd';
import { useProduct, usePaymentData, useScheduleData, useShippingData } from '../hooks/state';
import api from '../services/api';
import { useParams } from 'react-router-dom';
import '../assets/scss/ViewCheckOrder.scss';
import defaultProductPic from '../assets/img/defaultProductPic.png';
import { set } from 'lodash';
import moment from 'moment';

const SkeletonedTitle = ({ children, className }) => {
  return children ? (
    <div className={className}>{children}</div>
  ) : (
      <Skeleton className={'skeletoned ' + className} title={true} paragraph={false} active={true} />
    );
};

const OrderProduct = ({ id, item }) => {

  const product = useProduct(id);
  const defaultImage = defaultProductPic;

  if (!product) return null;

  return (
    <div className="orderProduct">

      <img
        className="unselectable"
        src={get(product, 'metadata.src') !== undefined ? get(product, 'metadata.src') : defaultImage}
        alt={product.alt}
      />

      <div className="info-order">
        <div className="name">{product.name}</div>
        <div className="price">$ {item.finalPrice.toFixed(2)}</div>
      </div>

      <div className="quantity-checkorder" style={{ width: '70px', paddingLeft: '5px', paddingRight: '5px', float: 'right', fontSize: '16px', }} >
        <div style={{ width: 'unset' }}> {item.quantity} </div>
      </div>
    </div>

  );
}

const OrderPayment = ({ name }) => {

  const paymentData = usePaymentData(name);

  if (!paymentData) return null;

  return (
    <Row className="customer">
      <Col span={12}>Médodo de pago: </Col>
      <Col span={12}> {paymentData.displayName} </Col>
    </Row>
  );
}

const OrderSchedule = ({ data }) => {

  const scheduleData = useScheduleData(data.type);

  if (!scheduleData) return null;

  return (
    <Row className="customer">
      <Col span={12}>Datos de horario: </Col>
      <Col span={12}> {scheduleData.displayName} {data.description ? ": " + data.description : null} </Col>
    </Row>
  );
}

const OrderShipping = ({ data }) => {

  const shippingData = useShippingData(data.type);

  if (!shippingData) return null;

  return (
    <Row className="customer">
      <Col span={12}>Datos de envío: </Col>
      <Col span={12}> {shippingData.displayName} {data.description ? ": Dirección: " + data.description : null} </Col>
    </Row>
  );
}

// const OrderCustomer = ({ customerData }) => {

//   // const customerData = useCustomerData(data);

//   console.log("ENTRE")
//   console.log(customerData);

//   if (!customerData) return null;

//   return (
//     <div>
//       Tus datos:
//       <div>
//         {customerData.fullName}
//       </div>
//       <div>
//         {customerData.email}
//       </div>
//       <div>
//         {customerData.phone_number}
//       </div>

//     </div>
//   );
// }

const ViewCheckOrder = () => {

  const [state] = useStateContext();
  const params = useParams();
  const { store } = state;

  // console.log(state);

  const [data, setData] = useState();

  useEffect(() => {

    if (!params.hash || !store.key) return;

    (async () => {
      const response = await api.getOrder(params.hash);
      if (!response) throw new Error('empty data');
      if(response.status==null && response.id_operation){
        const approved=await api.confirmMpPayment(params.hash,response.id_operation); 
        if(approved) set(response,'status','payed');
      }
      
      setData(response);
      
      
    })();

  }, [store.key, params.hash])


  // console.log("RESPONSE");
  // console.log(data);

  const prods = get(data, 'details.cart', []);
  const paymentData = get(data, 'details.paymentData');
  const scheduleData = get(data, 'details.scheduleData');
  const shippingData = get(data, 'details.shippingData');
  const customerData = get(data, 'details.customerData');

  const status = get(data, 'status');
  const total = get(data, 'details.total');

  const getStatus = () => {
    switch (status) {
      case "pending":
        return ("Pendiente");
      case "delivered":
        return ("Entregado");
      case "otw":
        return ("En camino");
      case "preparing":
        return ("Preparando");
      case "payed":
        return ("Pagado");
      case "cancelled":
        return ("Cancelado");
      case null:
        return ("Pendiente");
      default:
        return status
    }
  }

  const purchaseDate = get(data, 'purchase_date', '');
  const getFormattedDate = (date) => { const copy = new Date(date); const month = copy.getMonth() + 1; const day = copy.getDate(); const year = copy.getFullYear(); const hours = copy.getHours(); const minutes = copy.getMinutes(); return day + "/" + month + "/" + year + " " + hours + ":" + minutes; }
  const date = new Date(purchaseDate);
  const parsedPurchaseDate = getFormattedDate(date);

  if (!data) return null;

  return (

    <div className="container check-order-view">

      <div className="header">

        <div className="logo-container">
          {store.logoSrc ? (
            <div
              className="logo"
              style={{
                backgroundImage: `url(${store.logoSrc})`
              }}
            />
          ) : (
              <div
                className="logo"
              />
            )}
        </div>

        <div className="store-info">
          <SkeletonedTitle className="name">
            {store.name}
          </SkeletonedTitle>
          <SkeletonedTitle className="location">
            {store.location}
          </SkeletonedTitle>
          {store.phone_number ? (<SkeletonedTitle className="phone-number">
            {"Tel. " + store.phone_number}
          </SkeletonedTitle>) : null}
        </div>
      </div>


      <div className="container-grey">

        <div className="container-order-products">
          <div className="title"> Tu pedido. </div>
          {prods.map((prod, index) => {

            return (
              <OrderProduct id={prod.id} item={prod} key={index} />
            )
          })}

          <div className="total">{"Total: $ " + total} </div>
          <div className="status">{"Estado: " + getStatus()}</div>
        </div>
      </div>

      <div className="container-grey">
        <div className="container-customer">
          <div className="title"> Detalles de la compra. </div>

          <Row className=" customer">
            <Col span={12}>Fecha de compra:</Col>
            <Col span={12}>{parsedPurchaseDate}</Col>

          </Row>

          <OrderPayment name={paymentData.type} />
          <OrderSchedule data={scheduleData} />
          <OrderShipping data={shippingData} />

          <Row className="customer">
            <Col span={12}>Datos comprador:</Col>
            <Col span={12}>
              <div>
                {customerData.fullName}
              </div>
              <div>
                {customerData.email}
              </div>
              <div>
                {customerData.phone_number}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer"></div>
    </div>
  )
};

export default ViewCheckOrder;
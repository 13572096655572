import React from 'react';
import get from 'lodash/get';
import { Divider, Row, Col } from 'antd';

const CustomerData = ({ array, value, onChange, formik }) => {
  return (

    <Row className="dataContainer" >
      Datos comprador:
      <Col span={24} style={{marginLeft:'0px'}}>
        {array.map((element,index) => {
          const elementName = `customerData.${element.name}`;
          const value = formik.values[elementName];
          const touched = get(formik, `touched.${elementName}`);
          const error = get(formik, `errors.${elementName}`)
          return (
            <Row key={index}>
              <Col span={24}>
                <input className="inputText"
                  // id={elementName}
                  name={elementName}
                  type={get(element, "input.type", "text")}
                  placeholder={element.input.placeHolder}
                  // onChange={(ev) =>
                  //   onChange(
                  //     {
                  //       ...value,
                  //       [element.name]: ev.target.value,
                  //     })}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={value}
                />
                {touched && error ? (
                <div>{error}</div>
              ) : null}
              </Col>
              
              <Divider />
            </Row>
          );
        })}
      </Col>
    </Row>
  )
};

export default CustomerData;
import React from 'react';
import { useStateContext } from '../context/state';
import get from 'lodash/get';
import { Row, Col, message } from 'antd';
import '../assets/scss/CartFooter.scss';
import { useStoreHistory } from '../hooks/router';

const CartFooter = ({ onClick, busy = false, meta }) => {
  const history = useStoreHistory();
  const [state, dispatch] = useStateContext();
  const cart = get(state, "cart");
  const countProducts = cart.map(prod => prod.quantity).reduce((a, b) => a + b, 0);
  const totalPrice = cart.map(prod => prod.finalPrice).reduce((a, b) => a + b, 0);
  
  const goToView = () => {

    if((meta.errors || []).length){
      message.warning('Completá los campos requeridos antes');
    } else if (cart.length < 1) { 
      message.warning('Agregá productos antes');    
    } else {
      meta.goTo && history.push(`/${meta.goTo}`);
    }
  }

  return (

    <div className={`container-cartFooter ${busy ? 'busy' : ''}`} onClick={(ev) => !busy && onClick && onClick(ev)}>

      <Row className="cartFooter unselectable pointable" onClick={() => goToView()}>
        <Col span={6} className="count"><div>{countProducts}</div></Col>
        <Col span={11} className="pedido">{meta.title}</Col>
        <Col span={7} className="total">$ {totalPrice?totalPrice.toFixed(2):0}</Col>
      </Row>

    </div>

  )
};

export default CartFooter;
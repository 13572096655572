import { useRouteMatch, useHistory } from "react-router";

export const useRouteChildren = (routes) => {
  const match = useRouteMatch();
  
  const children = routes.reduce((ret, route) => {
    if (route.path !== match.path) return ret;
    return route.children;
  }, undefined);
  return children.map((child) => ({
    ...child,
    childPath: child.path,
    path: `${match.path}${child.path}`
  }));
}

export const useStoreHistory = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const push = (path) => {
    history.push(`/${match.url.split('/')[1]}${path}`)
  };

  const replace = (path) => {
    history.replace(`/${match.url.split('/')[1]}${path}`)
  };

  return {
    ...history,
    push,
    replace,
  }
}
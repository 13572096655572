import React, { Suspense } from 'react';
import './assets/scss/App.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import routes from './routes';
import 'antd/dist/antd.css';
import Gif from './assets/img/gifcaritas.gif';

const NoMatch = () => <div>Página no encontrado o no tiene permisos</div>;

const LoadingScreen = () => {
  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',

      }}
    >
      <img style={{ height: '75px' }} src={Gif} alt="Loading..." />
    </div>
  )
};

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routes.map((route) => (
            <Route key={route} {...route} children={[]} />
          ))}
          <Route component={() => <NoMatch />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

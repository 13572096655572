import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useStateContext } from '../context/state';
import '../assets/scss/MPView.scss';
import { useStoreHistory } from '../hooks/router';
import { Button } from 'antd';
import api from '../services/api';

import MPPayButton from '../components/MPPayButton';

const MP = () => {
  const [state] = useStateContext();
  const store = get(state, 'store');

  const title = get(state, 'store.mp.title', '¡Recibimos tu pedido!');
  const subtitle = get(state, 'store.mp.subtitle', "Solo falta que realices el pago para finalizar:");

  const key = get(state,'store.key');
  const hash = get(state,'hash');

  const textMPButton = get(state, 'store.mp.textMPButton', "Pagar con Mercado Pago");  

  const [urlMP, setUrlMP] = useState();

  useEffect(() => {

    //POST total and returns Pay Link MP

    const totalPrice = get(state, 'cart').map(prod => prod.finalPrice).reduce((a, b) => a + b, 0);

    let json = {}

    json['url_success'] = window.location.hostname+'/'+key+'/order/'+hash;
    // json['notification_url'] = window.location.hostname+'/orders/'+hash+'/notifications';

    // json['name'] = get(state, 'customerData.fullName');
    // json['phone_number'] = get(state, 'customerData.phone_number');

    // json['notification_url'] = "https://hookb.in/1gwL6GWN7NTj002yk7mz";

    json['total'] = totalPrice;
    
    console.log(json);

    (async () => {
      console.log("ENTRE A CONFIRM POST MP PAY API");
      const response = await api.postMPPay(hash,json);

      if (response) {
        setUrlMP(response);
      }

    })();

  }, []);

  return (
    <div className="container mp-view">

      <div className="logo-container">

        {store.logoSrc ? (
          <div
            className="logo"
            style={{
              backgroundImage: `url(${store.logoSrc})`
            }}
          />
        ) : (
            <div
              className="logo"
            />
          )}
      </div>

      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>

      <MPPayButton
        urlMP={urlMP}
        textButton={textMPButton}
      />

      <div className="container-powered">
        <a href="http://www.smileweb.net/" target="_blank">
          <img className="poweredSmile" src="https://s3-sa-east-1.amazonaws.com/static.smileweb.net/images/powered_by_smile.png" />
        </a>
      </div>

    </div >
  )
};

export default MP;
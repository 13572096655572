import React, { useEffect } from 'react';
import get from 'lodash/get';
import { Row, Col } from 'antd';
import { useFormikField } from '../../hooks/formik';
// import DatePicker from 'react-mobile-datepicker';
// import FormList from 'antd/lib/form/FormList';
// import moment from 'moment';

// const format = 'HH:mm';

// const dateConfig = {
//   'hour': {
//       format: 'hh',
//       caption: 'Hora',
//       step: 1,
//   },
//   'minute': {
//       format: 'mm',
//       caption: 'Minuto',
//       step: 1,
//   },
// }


const ScheduleType = ({ array, formik }) => {

  const elementValid = useFormikField(formik, 'scheduleData._valid');
  const elementType = useFormikField(formik, 'scheduleData.type');
  const elementDescription = useFormikField(formik, 'scheduleData.description');

  useEffect(() => {
    if (elementType.value === 'now') {
      elementValid.setValue(true);
      return;
    }
    if (elementType.value === 'time' && elementDescription.value) {
      elementValid.setValue(true);
      return;
    }
    elementValid.setValue(false);
  }, [formik, elementValid.value, elementType.value, elementDescription.value])

  const touched = elementType.touched || elementDescription.touched;


  // const time = new Date();


  // console.log(formik);
  // console.log(elementType.value);


  // const handleCancel = () =>{

    
  //   setShowPicker(false);
  // }

  // console.log(elementType);

  // console.log(formik);

  // const [showPicker,setShowPicker] = useState(true);

  // const handleCancel = () => {
  //   setShowPicker(false);
  // }
  // const handleSelect = (time) => {
  //   console.log(time);

  //   formik.handleChange("a");
    
    

  //   setShowPicker(false);
  // }
  return (

    <Row className="dataContainer" >
      Definir horario:
      <Col span={24}>
        {array.map((element,index) => {

          return (
            <Row key={index}className="pointable">
              <Col onClick={() => elementType.setValue(element.name)} span={22}>
                {element.displayName ? (element.displayName) : null}
              </Col>

              {!element.displayName ? null :

                elementType.value === element.name ?
                  (
                    <Col onClick={() => elementType.setValue(element.name)} span={1}><i className={"sm-square-checked"} /></Col>
                  ) :
                  (
                    <Col onClick={() => elementType.setValue(element.name)} span={1}><i className={"sm-square-unchecked"} /></Col>
                  )
              }
              
              {get(element, "input.type") === "time" && elementType.value === "time" ?
                
                (<input 
                  className="inputTime"
                  type = "time"
                  placeholder={element.input.placeHolder}
                  // onChange={(ev) => onChange({ type: element.name, description: ev.target.value })}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name={elementDescription.path}
                  value={elementDescription.value}
                />)
                : null}


             <Col span={1}/>
             <hr className="solid"/>
            </Row>
          );

        })}
      </Col>
      {touched && elementValid.error ? (
        <div>{elementValid.error}</div>
      ) : null}
    </Row>
  )
};

export default ScheduleType;
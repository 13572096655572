import React from 'react';
import get from 'lodash/get';
import { Row, Col } from 'antd';

const PaymentMethod = ({ array, value, formik }) => {

  const elementType = { value: get(formik, "values.paymentData.type") };
  // console.log(elementType);
  // const elementType = useFormikField(formik, 'scheduleData.type');

  // console.log(formik);
  return (

    <Row className="dataContainer" >
      Método de pago:
      <Col span={24}>
        {array.map((element,index) => (
          // <Row onClick={() => onChange({ type: element.name })} className="pointable">
          <Row key={index} onClick={() => formik.setFieldValue('paymentData.type', element.name)} className="pointable">
            <Col span={22}>
              {element.displayName ? (element.displayName) : null}
            </Col>

            {!element.displayName ? null :

              elementType.value === element.name ?
                (
                  <Col span={1}><i className={"sm-square-checked"} /></Col>
                ) :
                (
                  <Col span={1}><i className={"sm-square-unchecked"} /></Col>
                )
            }
            <Col span={1}/>
            <hr className="solid" />
          </Row>
        ))}
      </Col>
    </Row>
  )
};

export default PaymentMethod;
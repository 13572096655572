import React, { useState, Fragment } from 'react';
import { useStateContext } from '../context/state';
import get from 'lodash/get';
import '../assets/scss/ViewCheckout.scss';
import CartFooter from '../components/CartFooter';
import Close from '../components/Close';
import ShippingType from '../components/checkout/ShippingType';
import ScheduleType from '../components/checkout/ScheduleType';
import PaymentMethod from '../components/checkout/PaymentMethod';
import CustomerData from '../components/checkout/CustomerData';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStoreHistory } from '../hooks/router';
import api from '../services/api';
import { Skeleton } from 'antd';

import MPPayButton from '../components/MPPayButton';


const CheckoutForm = ({
  customerData,
  paymentMethod,
  scheduleType,
  shippingType,
  onSubmit,
  onChangeObservation,
  busy,
 }) => {
  const formik = useFormik({
    initialValues: {
      customerData: {
        fullName: '',
        phone_number: '',
        email: '',
      },
      shippingData: {
        type: shippingType[0].name,
        _valid: false,
      },
      scheduleData: {
        type: scheduleType[0].name,
        _valid: false,
      },
      paymentData: {
        type: paymentMethod[0].name,
      }
    },

    validationSchema: Yup.object({
      shippingData: Yup.object({
        _valid: Yup.boolean().oneOf([true], '* Direccion de envío requerida')
      }),
      scheduleData: Yup.object({
        _valid: Yup.boolean().oneOf([true], '* Hora requerida')
      }),
      paymentData: Yup.object({
        type: Yup.mixed().oneOf(['cash', 'mercado-pago'], 'Invalid')
      }),
      customerData: Yup.object({
        fullName: Yup.string().required('* Nombre y apellido requerido'),
        phone_number: Yup.string().required('* Telefono requerido'),
        email: Yup.string().email('* Mail inválido').required('* Mail requerido'),

      }).required('Required')
    }),

    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ShippingType
        array={shippingType}
        formik={formik}
      />

      <ScheduleType
        array={scheduleType}
        formik={formik}
      />

      <PaymentMethod
        array={paymentMethod}
        formik={formik}
      />

      <CustomerData
        array={customerData}
        formik={formik}
      />

      <div className="container-confirmation-observation">
        Alguna aclaración que quieras hacer:
      <textarea className="text"
          placeholder="Escribinos tu aclaración aca"
          onChange={(ev) => onChangeObservation(ev.target.value)}
        />
      </div>

      <div style={{ maxWidth: 'inherit' }}>
        <CartFooter
          onClick={formik.submitForm}
          busy={busy}
          meta={{
            // goTo: canPass && get(formik.values, 'paymentData.type') === 'mercado-pago' ? 'mp' : canPass ? 'thanks' : undefined,
            
            title: 'Confirmar pedido',
            errors: formik.errors
          }}
        />
      </div>

    </form>
  );
}

const ViewCheckout = () => {

  const history = useStoreHistory();
  const [state, dispatch] = useStateContext();

  const close = () => {

    history.push(`/`);

  }

  // console.log("STATEEEEEE");
  // console.log(state);

  // console.log(state.cart);
  const [busy, setBusy] = useState(false);

  const metadata = get(state, "store.checkout", {});
  const shippingType = get(metadata, "shippingType", []);
  const scheduleType = get(metadata, "scheduleType", []);
  const paymentMethod = get(metadata, "paymentMethod", []);
  const customerData = get(metadata, "customerData", []);

  const [observation, setObservation] = useState('');

  const goToNext = (values) => {
    const path = get(values, 'paymentData.type') === 'mercado-pago' ? 'mp' : 'thanks';
    history.push(`/${path}`);
  }


  // console.log(shippingType);

  return (
    <div className="container unselectable negrita">

      <Close onClick={close} />

      <div className="checkout-title">Confirmación.</div>

      {get(state, 'store.checkout') ? (

        <CheckoutForm
          {...{
            shippingType,
            scheduleType,
            paymentMethod,
            customerData,
            busy,
          }}
          onChangeObservation={setObservation}
          onSubmit={async (values) => {
            setBusy(true);
            // dispatch({
            //   type: 'customerData.update',
            //   payload: customerData,
            // });

            // values['cart'] = get(state,'cart');

            const totalPrice = get(state, 'cart').map(prod => prod.finalPrice).reduce((a, b) => a + b, 0);

            values['cart'] = get(state, 'cart').map((product) => ({
              ...product,
              extras: product.additionals,
              additionals: undefined,
            }));

            values['total'] = totalPrice;

            // console.log("values cart");
            // console.log(values['cart']);
            values['hash'] = get(state, 'hash');
            values['observation'] = observation;

            console.log("ENTRE A CONFIRM CART API");
            try {
              const response = await api.confirmCart(values);
  
              if (response) {
                goToNext(values);
              }
            } catch (err) {
              setBusy(false);
              console.log(err);
            }

            // console.log(response);

          }}
        />
      ) : (
          <Fragment>
            {Array.from({ length: 10 }).map((line, index) => (
              <Skeleton key={index} title={true} paragraph={false} active={true} />
            ))}
          </Fragment>
        )}
    </div>
  )
};

export default ViewCheckout;
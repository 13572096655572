import React from 'react';
import { Button } from 'antd';

const MPPayButton = ({ urlMP, textButton }) => {

  return (
    <div className="mp-button">
      {urlMP ? (
        <Button type="primary" className="button-add ignore-disabled-style radius" size="large" >
          <a href={urlMP} target="_blank" > {textButton}</a>
        </Button>
      ) :
        <Button type="primary" className="button-add ignore-disabled-style radius" size="large" loading > Cargando link de pago...</Button>}
    </div>
  )
}

export default MPPayButton
import React from 'react';
import { useStateContext } from '../context/state';
import get from 'lodash/get';
import '../assets/scss/Header.scss';
import { Skeleton } from 'antd';

const SkeletonedTitle = ({ children, className }) => {
  return children ? (
    <div className={className}>{children}</div>
  ) : (
      <Skeleton className={'skeletoned ' + className} title={true} paragraph={false} active={true} />
    );
};

const Header = () => {
  const [state] = useStateContext();
  const store = get(state, 'store');

  return (
    <div className="header">

      <div className="cover" style={{
        backgroundImage: `url(${store.coverSrc})`
      }} />
      <div className="logo-container">

        {store.logoSrc ? (
          <div
            className="logo absolute"
            style={{
              backgroundImage: `url(${store.logoSrc})`
            }}
          />
        ) : (
            <div
              className="logo absolute"
            />
          )}
      </div>

      <div className="info">
        <SkeletonedTitle className="name">
          {store.name}
        </SkeletonedTitle>
        <SkeletonedTitle className="description">
          {store.description}
        </SkeletonedTitle>
        <SkeletonedTitle className="location">
          <div>{store.location}</div>
          <div className="location-link">
            {store.locationLink ?
              (<a href={store.locationLink} target="_blank">Ver mapa</a>)
              : null}
          </div>

        </SkeletonedTitle>
      </div>

      {store.hours ? (
        <div className="hours">
          <div>
            {store.hours}
          </div>
        </div>
      ) : null}

    </div>
  )
};

export default Header;
import React, { useState, useEffect } from 'react';
import { useStateContext } from '../context/state';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import '../assets/scss/ViewOrder.scss';
import { Row, Popconfirm, message } from 'antd';
import Close from '../components/Close';
import CartFooter from '../components/CartFooter';
import { useStoreHistory } from '../hooks/router';
import defaultProductPic from '../assets/img/defaultProductPic.png';


const ConfirmProduct = ({ onChange, onConfirm, children: childrenFunction, ...props }) => {
  const [changeArgs, setChangeArgs] = useState(undefined);

  const change = (...args) => {
    const needConfirm = onChange(...args);
    if (needConfirm === undefined) return;
    if (!needConfirm) {
      return onConfirm(...args);
    }
    setChangeArgs(args);
  };

  const children = isFunction(childrenFunction) ? childrenFunction({ change }) : childrenFunction;

  return (
    <Popconfirm
      visible={changeArgs !== undefined}
      onConfirm={() => {
        onConfirm(...changeArgs);
        setChangeArgs(undefined);
      }}
      onCancel={() => {
        setChangeArgs(undefined);
      }}
      {...props}
    >
      {children}
    </Popconfirm>
  )
}

const ViewOrder = () => {

  const history = useStoreHistory();

  const [state, dispatch] = useStateContext();

  const [popupVisible, setPopupVisible] = useState(false);

  const prods = get(state, "cart");

  const popupText = "¿Estás seguro de eliminar el producto?";
  const popupVaciar = "¿Estás seguro de querer vaciar el pedido?";

  const defaultImage = defaultProductPic;

  useEffect(() => {
    dispatch({
      type: 'cart.removeProdsWithoutQuantity',
    });
    return () => {
      dispatch({
        type: 'cart.removeProdsWithoutQuantity',
      });
    }
  }, []);

  const close = () => {

    history.push(`/`);

  }

  const handleQuantity = (prod, index, num) => {
    // MODIFY QUANTITY AND FINAL PRICE ON PRODS IN THE CART
    if (prod.quantity === 0 && num < 0) {
      return undefined;
    } else if (prod.quantity === 1 && num < 0) {
      return true;
    }
    return false;
  }

  const updateProd = (prod, num, index) => {
    const auxProduct = { ...prod };
    auxProduct.quantity = prod.quantity + num;
    auxProduct.finalPrice = prod.unitPrice * (prod.quantity + num);

    dispatch({
      type: 'cart.editItem',
      payload: auxProduct,
      index: index,
    });
  }

  const confirm = (prod, index, num) => {
    setPopupVisible(false);
    updateProd(prod, num, index);
  }

  const goToEditProduct = (index) => {

    history.push(`/edit-item/${index}`);

  }

  const dropCart = () => {
    dispatch({
      type: 'cart.clear',
    });
  }

  const confirmVaciar = () => {
    message.info("Pedido vaciado");
    dropCart();
    close();
  }

  if (prods.length === 0) history.push('/');

  return (
    <div className="container" style={{ paddingBottom: '100px' }}>

      <Close onClick={close} />

      <div className="order-title">Tu pedido.</div>

      {prods.map((prod, index) => {

        return (
          <div key={index} className="orderProduct unselectable">

            <img
              className="pointable"
              onClick={() => goToEditProduct(index)}
              src={get(prod, 'metadata.src') !== undefined ? get(prod, 'metadata.src') : defaultImage}
              alt={prod.alt}
            />

            <div className="info-order pointable" onClick={() => goToEditProduct(index)}>
              <div className="name">{prod.name}</div>
              <div className="price">$ {prod.finalPrice.toFixed(2)}</div>
            </div>
            <ConfirmProduct
              onConfirm={confirm}
              onChange={handleQuantity}
              title={popupText}
              placement="left"
              okText="Si"
              cancelText="No"
            >
              {({ change }) => (
                <div className="quantity" style={{ float: 'right', fontSize: '16px' }} >
                  <div className="pointable" onClick={() => change(prod, index, -1)}> - </div>
                  <div> {prod.quantity} </div>
                  <div className="pointable" onClick={() => change(prod, index, +1)}> + </div>
                </div>
              )}
            </ConfirmProduct>
          </div>

        );
      })}

      <Popconfirm placement="top" title={popupVaciar} onConfirm={confirmVaciar} okText="Si" cancelText="No">
        <Row className="vaciar unselectable pointable">Vaciar Pedido</Row>
      </Popconfirm>

      <CartFooter
        meta={{
          goTo: 'checkout',
          title: 'Confirmar pedido'
        }}
      />

    </div>
  )
};

export default ViewOrder;
import React from 'react';
import ViewEditProduct from './views/EditProduct';
import ViewOrder from './views/Order';
import ViewProducts from './views/Products';
import ViewCheckout from './views/Checkout';
import ViewThanks from './views/Thanks';
import ViewCheckOrder from './views/CheckOrder';
import ViewMP from './views/MP';
import StoreApp from './App';
import { useHistory, Redirect } from 'react-router';

const Soon = () => (
  <div>Soon!</div>
);

// const Test = () => {
//   const params = useParams();

//   return (
//     <div>{params.id}</div>
//   );
// };

const Redirector = () => {
  const history = useHistory();

  history.replace('/zucco-san-justo');
  return null;
};

export default [
  {
    path: '/',
    exact: true,
    component: Redirector,
  },
  {
    path: '/:store?',
    component: StoreApp,
    children: [
      {
        path: '/',
        exact: true,
        component: ViewProducts,
      },
      {
        path: '/add-product/:productId',
        component: ViewEditProduct,
      },
      {
        path: '/edit-item/:index',
        component: ViewEditProduct,
      },
      {
        path: '/order',
        exact: true,
        component: ViewOrder,
      },
      {
        path: '/checkout',
        component: ViewCheckout,
      },
      {
        path: '/thanks',
        component: ViewThanks,
      },
      {
        path: '/order/:hash',
        component: ViewCheckOrder,
      },
      {
        path:'/mp',
        component:ViewMP,
      }
      
    ]
  }
]

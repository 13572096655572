import React from 'react';
import get from 'lodash/get';
import { useStateContext } from '../context/state';
import '../assets/scss/Thanks.scss';
import { useStoreHistory } from '../hooks/router';
import { Button } from 'antd';

const ViewThanks = () => {
  const [state] = useStateContext();
  const history = useStoreHistory();
  const store = get(state, 'store');
  const hash = get(state, 'hash');

  const title = get(state, 'store.thanks.title', '¡Gracias por tu compra!');
  const subtitle = get(state, 'store.thanks.subtitle', "En el siguiente link podés\n ver el detalle de tu pedido");

  const goToCheckOrder = () => {

    history.push(`/order/${hash}`);

  }

  return (
    <div className="container thanks-view">

      <div className="logo-container">

        {store.logoSrc ? (
          <div
            className="logo"
            style={{
              backgroundImage: `url(${store.logoSrc})`
            }}
          />
        ) : (
            <div
              className="logo"
            />
          )}
      </div>

      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>

      {/* <div className="link-hash" onClick={() => goToCheckOrder()}> Ver pedido </div> */}
      <div className="button-container">
        <Button type="primary" className="button-add ignore-disabled-style radius" size="large" onClick={() => goToCheckOrder()} > Ver pedido </Button>
      </div>

      <div className="container-powered">
        <a href="http://www.smileweb.net/" target="_blank">
          <img className="poweredSmile" src="https://s3-sa-east-1.amazonaws.com/static.smileweb.net/images/powered_by_smile.png" />
        </a>
      </div>

    </div >
  )
};

export default ViewThanks;
/* eslint-disable no-case-declarations */
import React, { createContext, useReducer, useContext } from 'react';

const initialState = {
  session: {},
  cart: [],
  hash: undefined,
  store: {},
  prods: [],
  categories: [],
  extras_categories: [],
  extras: [],
  selectedProduct: {},
  customerData: {},
  view: { from: "home", actual: "home" },
}

export const store = createContext(initialState);

export const useStateContext = () => useContext(store);


const newCart = (cart, callback) => {
  if (callback) callback(cart);
  localStorage.setItem("cart", JSON.stringify(cart));
  return cart;
}

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'cart.clear':
        return {
          ...state,
          cart: newCart([
            ...initialState.cart,
          ]),
        };
      case 'cart.set':
        return {
          ...state,
          cart: newCart([
            ...action.payload,
          ]),
        };
        case 'cart.addItem':
        return {
          ...state,
          cart: newCart([
            ...state.cart,
            ...action.payload,
          ]),
        };
      case 'cart.editItem':
        return {
          ...state,
          cart: newCart([
            ...state.cart,
          ], (cart) => cart[action.index] = action.payload),
        };
      case 'cart.removeProdsWithoutQuantity': {
        return {
          ...state,
          cart: newCart(state.cart.filter(prod => prod.quantity !== 0)),
        }
      }
      case 'prods.clear':
        return {
          ...state,
          prods: [
            ...initialState.prods,
          ],
        };
      case 'prods.update':
        return {
          ...state,
          prods: [
            ...state.prods,
            ...action.payload,
          ],
        };
      case 'categories.clear':
        return {
          ...state,
          categories: [
            ...initialState.categories,
          ],
        };
      case 'categories.update':
        return {
          ...state,
          categories: [
            ...state.categories,
            ...action.payload,
          ],
        };
      case 'store.clear':
        return {
          ...state,
          store: {
            ...initialState.store,
          },
        };
      case 'store.update':
        return {
          ...state,
          store: {
            ...state.store,
            ...action.payload,
          },
        };
      case 'extras_categories.clear':
        return {
          ...state,
          extras_categories: [
            ...initialState.extras_categories,
          ],
        };
      case 'extras_categories.update':
        return {
          ...state,
          extras_categories: [
            ...state.extras_categories,
            ...action.payload,
          ],
        };
      case 'extras.clear':
        return {
          ...state,
          extras: [
            ...initialState.extras,
          ],
        };
      case 'extras.update':
        return {
          ...state,
          extras: [
            ...state.extras,
            ...action.payload,
          ],
        };
      case 'view.clear':
        return {
          ...state,
          view: {
            ...initialState.view,
          },
        };
      case 'view.update':
        return {
          ...state,
          view: {
            from: state.view.actual,
            actual: action.payload.actual,
          },
        };
      case 'session.clear':
        return {
          ...state,
          session: {
            ...initialState.session,
          },
        };
      case 'session.update':
        return {
          ...state,
          session: {
            ...state.session,
            ...action.payload,
          },
        };
      case 'customerData.clear':
        return {
          ...state,
          customerData: {
            ...initialState.customerData,
          },
        };
      case 'customerData.update':
        // console.log("entre a update");
        // console.log(action.payload);
        return {
          ...state,
          customerData: {
            ...state.customerData,
            ...action.payload,
          },
        };
      case 'clear':
        return {
          ...initialState,
        };
      case 'update':
        return {
          ...state,
          ...action.payload,
        };
      case 'hash.clear':
        return {
          ...state,
          hash: initialState.hash,
        };
      case 'hash.update':
        return {
          ...state,
          hash: action.payload,
        };     
      default:
        throw new Error();
    };
  }, initialState);
  return <store.Provider value={[state, dispatch]}>{children}</store.Provider>;
}

import React, { useEffect } from 'react';
import get from 'lodash/get';
import { Row, Col } from 'antd';
import { useFormikField } from '../../hooks/formik';

const ShippingType = ({ array, formik }) => {
  const elementValid = useFormikField(formik, 'shippingData._valid');
  const elementType = useFormikField(formik, 'shippingData.type');
  const elementDescription = useFormikField(formik, 'shippingData.description');

  useEffect(() => {
    if (elementType.value === 'take-away') {
      elementValid.setValue(true);
      return;
    }
    if (elementType.value === 'delivery' && elementDescription.value) {
      elementValid.setValue(true);
      return;
    }
    elementValid.setValue(false);
  }, [formik, elementValid.value, elementType.value, elementDescription.value])


  const touched = elementType.touched || elementDescription.touched;

  return (

    <Row className="dataContainer" >
      Tipo de envío:
      <Col span={24}>
        {array.map((element,index) => {

          return (
            <Row key={index} className="pointable">

              <Col onClick={() => elementType.setValue(element.name)} span={22}>
                {element.displayName ? (element.displayName) : null}
              </Col>

              {!element.displayName ? null :

                elementType.value === element.name ?
                  (
                    <Col onClick={() => elementType.setValue(element.name)} span={1}><i className={"sm-square-checked"} /></Col>
                  ) :
                  (
                    <Col onClick={() => elementType.setValue(element.name)} span={1}><i className={"sm-square-unchecked"} /></Col>
                  )
              }
              {get(element, "input.type") === "text" && elementType.value === "delivery" ?
                (<textarea className="inputTextArea"
                  placeholder={element.input.placeHolder}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name={elementDescription.path}
                  value={elementDescription.value}

                />)
                : null}
              <Col span={1} />
              <hr className="solid" />
            </Row>
          );
        })}
      </Col>
      {touched && elementValid.error ? (
        <div>{elementValid.error}</div>
      ) : null}
    </Row>
  )
};

export default ShippingType;
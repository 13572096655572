import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URI || 'https://devtest.smileweb.net/api/';

export default {

  get storeKey() {
    return axios.defaults.headers.common['X-Store']; 
  },
  set storeKey(key) {
    axios.defaults.headers.common = {
      'X-Store': key,
      'Content-Type':'application/json',
    };    
  },
  async getMainData(){
    try {
      const { data } = await axios({
        url: `${apiUrl}ecommerce`, 
      });
      // console.log("MAIN DATA");
      // console.log(data);
      return data;
    } catch(error) {
      console.log('get error');
      console.error('get helper error ' + error);
      throw error;
    }
  },
  async getCart() {   
    try {
      const { data } = await axios({
        url: `${apiUrl}ecommerce/cart`,
      });
      return data;
    } catch(error) {
      console.log('get error');
      console.error('get helper error ' + error);
      throw error;
    }
  },
  async getProductById(id) {   
    try {
      const { data } = await axios({
        url: `${apiUrl}ecommerce/products/${id}`,
      });
      return data;
    } catch(error) {
      console.log('get error');
      console.error('get helper error ' + error);
      throw error;
    }
  },
  async confirmCart(json){
    try {
      // console.log(json);
      const { data } = await axios({
        url: `${apiUrl}ecommerce/orders`,
        method: 'post',
        data: json,
      });
      return data;
    } catch (error) {
      console.error('post helper error ' + error);
    }
  },
  async getOrder(hash) {   
    try {
      const { data } = await axios({
        url: `${apiUrl}ecommerce/orders/${hash}`,
      });
      return data;
    } catch(error) {
      console.log('get error');
      console.error('get helper error ' + error);
      throw error;
    }
  },

  async postMPPay(hash,json) {   
    try {
      const { data } = await axios({
        url: `${apiUrl}ecommerce/orders/${hash}/pay?is-dev=true`,
        method: 'post',
        data: json,
      });
      return data;
    } catch(error) {
      console.log('get error');
      console.error('get helper error ' + error);
      throw error;
    }
  },

  async confirmMpPayment(hash,id) {   
    try {
      const { data } = await axios({
        url: `${apiUrl}ecommerce/orders/${hash}/notifications?is-dev=true`,
        method: 'post',
        data: { action: 'payment.created',id:id}
      });
      return data;
    } catch(error) {
      console.log('post error');
      console.error('post helper error ' + error);
      throw error;
    }
  },

  async put(url,options = {}) {
    var response = axios({
      method: 'put',
      url: `${apiUrl}` + url,
      params: options.query,
    })
      .then(responseJson => {
        return responseJson.data;
      })
      .catch(error => {
        console.error('PUT request', error);
      });
    return response;
  }

};

import { useState, useEffect } from 'react';
import { useStateContext } from '../context/state';
import get from 'lodash/get';

export const useProduct = (id) => {
  const [state] = useStateContext();
  const [product, setProduct] = useState(undefined);
  useEffect(() => {
    setProduct(state.prods.find((prod) => prod.id === id));
  }, [id, state.prods])
  return product;
}

export const usePaymentData = (name) => {
  const [state] = useStateContext();
  const [data, setData] = useState(undefined);
  useEffect(() => {
    setData(get(state,'store.checkout.paymentMethod',[]).find((filter) => filter.name === name));
  }, [name, state.store])
  return data;
}

export const useScheduleData = (name) => {
  const [state] = useStateContext();
  const [data, setData] = useState(undefined);
  useEffect(() => {
    setData(get(state,'store.checkout.scheduleType',[]).find((filter) => filter.name === name));
  }, [name, state.store])
  return data;
}

export const useShippingData = (name) => {
  const [state] = useStateContext();
  const [data, setData] = useState(undefined);
  useEffect(() => {
    setData(get(state,'store.checkout.shippingType',[]).find((filter) => filter.name === name));
  }, [name, state.store])
  return data;
}

// export const useCustomerData = (param) => {
//   const [state] = useStateContext();
//   const [data, setData] = useState(undefined);

//   console.log("DATA");
//   console.log(param);

//   console.log()

//   console.log(get(state,'store.checkout.customerData',[]));

//   useEffect(() => {
//     // setData(get(state,'store.checkout.customerData',[]).filter((filter) => filter.name === name));
//   }, [param, state.store])
//   return data;
// }
import React from 'react';
import Header from '../components/Header';
import CartFooter from '../components/CartFooter';
import Products from '../components/Products';
import { useStateContext } from '../context/state';
import get from 'lodash/get';


const ViewProducts = ({ prods }) => {

  const [state] = useStateContext();

  return (
    <div className="container">
      <Header
      />
      <div className="app-body">
        <Products
          prods={prods}
        />
      </div>
      {get(state,'store.name')?(
      <CartFooter
        meta={{
          goTo: 'order',
          title: 'Ver pedido'
        }}
      />
      ):null}
    </div>
  )
};

export default ViewProducts;
import React, { useState, useEffect } from 'react';
import { useStateContext } from '../context/state';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import pick from 'lodash/pick';
import difference from 'lodash/difference';
import '../assets/scss/ViewEditProduct.scss';
import { Divider, Row, Col, Button, Spin } from 'antd';
import { useParams, useLocation } from 'react-router-dom';
import Close from '../components/Close';
import api from '../services/api';
import { useStoreHistory } from '../hooks/router';
import { LoadingOutlined } from '@ant-design/icons';
import defaultProductPic from '../assets/img/defaultProductPic.png';

const ViewEditProduct = () => {

  const [state, dispatch] = useStateContext();
  const params = useParams();
  const history = useStoreHistory();
  const location = useLocation();

  const defaultImage = defaultProductPic;

  const [selectedProd, setSelectedProd] = useState();
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [quantity, setQuantity] = useState();
  const [partialPrice, setPartialPrice] = useState(0);
  const [note, setNote] = useState();
  const [unitPrice, setUnitPrice] = useState();

  const cart = get(state, "cart", []);
  const { store } = state;

  useEffect(() => {
    const prod = state.prods.find((prod) => prod.id === Number(params.productId));
    // console.log(params.productId);
    if (!prod) return;
    setSelectedProd({
      ...prod,
      partial: true,
    });

  }, [params.productId, state.prods]);

  // console.log('selectedProd', selectedProd);
  // console.log('selectedExtras', selectedExtras);
  useEffect(() => {
    if (!params.productId || !store.key) return;

    (async () => {
      const response = await api.getProductById(params.productId);

      if (!response) throw new Error('empty data');

      setSelectedProd(response);

    })();

  }, [store.key, params.productId]);

  useEffect(() => {
    if (!cart || !params.index) return;

    const selectedProd = cart[params.index];
    setSelectedProd(selectedProd);
  }, [cart, params.index]);

  useEffect(() => {
    if (!selectedProd) return;
    setSelectedExtras(get(selectedProd, "additionals", []));
    setQuantity(get(selectedProd, "quantity", 1));
    setPartialPrice(get(selectedProd, "finalPrice", selectedProd.price));
    setNote(get(selectedProd, 'note', ''));
    setUnitPrice(selectedProd.price);
  }, [selectedProd])

  // For render extras by product
  const mapExtras = get(selectedProd, 'extras', []);

  const mapTypes = uniqBy(mapExtras, 'type_id');
  const filteredTypes = mapTypes.map(type => {
    const picked = pick(type, ['type_id', 'type_name', 'max', 'min', 'multiple']);
    return picked;
  });

  const requiredExtraTypes = filteredTypes.filter((extra_type) => extra_type.min > 0).map(filtered => filtered.type_id);
  const [extraTypesWithErrors, setExtraTypesWithErrors] = useState([]);

  const close = () => {
    location.pathname.includes('/add-product/') ?
      history.push(`/`) :
      location.pathname.includes('/edit-item/') ?
        history.push(`/order/`) :
        history.push(`/home/`)
  }

  const handleExtra = (selectedExtra, extraType) => {

    // console.log(selectedExtra);

    selectedExtra['quantity'] = 1;

    let auxSelectedExtras = [
      ...selectedExtras,
    ];

    selectedExtras.includes(selectedExtra) ?
      auxSelectedExtras = auxSelectedExtras.filter(filter => filter !== selectedExtra)
      : auxSelectedExtras.push(selectedExtra);

    let reducedExtras = auxSelectedExtras.reduce((ret, extra) => {
      const type = filteredTypes.find((type) => type.type_id === extra.type_id);
      const extraTypes = ret.filter((filter) => filter.type_id === extra.type_id);
      extraTypes.push(extra);
      return [
        ...ret.filter((filter) => filter.type_id !== extra.type_id),
        ...extraTypes.slice(-type.max),
      ];
    }, []);

    auxSelectedExtras = [...reducedExtras];
    setSelectedExtras(auxSelectedExtras);

    // SUM ADDITIONAL PRICE TO PARTIAL PRICE
    const addPrice = auxSelectedExtras.map(extra => extra.price).reduce((a, b) => a + b, 0);
    setUnitPrice(selectedProd.price + addPrice);
    setPartialPrice((selectedProd.price + addPrice) * quantity);
  }

  const handleMultipleExtra = (selectedExtra, num) => {

    // console.log(selectedExtra);
    // console.log(num);

    let auxSelectedExtras = [
      ...selectedExtras,
    ];

    const maxQuantityOfExtraType = selectedExtra.max;
    const quantityOfExtraType = auxSelectedExtras
      .filter(filter => filter.type_id === selectedExtra.type_id)
      .map(extra => extra.quantity)
      .reduce((a, b) => a + b, 0);

    if (quantityOfExtraType >= maxQuantityOfExtraType && num > 0) {
      return;
    }

    const auxExtra = selectedExtras.find(extra => extra.id === selectedExtra.id);
    if (!auxExtra && num > 0) {
      selectedExtra['quantity'] = 1;
      auxSelectedExtras.push(selectedExtra);
    }
    else {
      // Si tengo cantidad 0 del extra y quiero restar no hace nada
      if ((get(auxExtra, 'quantity') === 0 || get(auxExtra, 'quantity') === undefined) && num < 0) {
        return;
      }
      else if (num < 0 && auxExtra['quantity'] === 1) {
        auxExtra['quantity'] = auxExtra['quantity'] + num;
        auxSelectedExtras = auxSelectedExtras.filter(filter => filter !== selectedExtra);
      }
      else {
        auxExtra['quantity'] = auxExtra['quantity'] + num;
      }
    }

    setSelectedExtras(auxSelectedExtras);

    // SUM ADDITIONAL PRICE TO PARTIAL PRICE
    const addPrice = auxSelectedExtras.map(extra => extra.price).reduce((a, b) => a + b, 0);
    setUnitPrice(selectedProd.price + (addPrice * (auxExtra ? auxExtra['quantity'] : 1)));
    setPartialPrice((selectedProd.price + (addPrice * (auxExtra ? auxExtra['quantity'] : 1))) * quantity);

  }

  const handleQuantity = (num) => {
    // SUM ADDITIONAL PRICE TO PARTIAL PRICE
    let auxSelectedExtras = [
      ...selectedExtras
    ];
    const addPrice = auxSelectedExtras.map(extra => extra.price).reduce((a, b) => a + b, 0);
    setUnitPrice(selectedProd.price + addPrice);
    setPartialPrice((selectedProd.price + addPrice) * (quantity + num));

    setQuantity(quantity + num);

  }
  const agregarProducto = () => {

    if (checkRequiredErrors()) {
      return;
    }

    dispatch({
      type: 'cart.addItem',
      payload: [{
        ...selectedProd,
        quantity: quantity,
        additionals: selectedExtras,
        note: note,
        unitPrice: unitPrice,
        finalPrice: partialPrice,
      }],
    });

    history.push(`/`);

  }

  const editarProducto = () => {

    if (checkRequiredErrors()) {
      return;
    }

    dispatch({
      type: 'cart.editItem',
      payload: {
        ...selectedProd,
        quantity: quantity,
        additionals: selectedExtras,
        note: note,
        unitPrice: unitPrice,
        finalPrice: partialPrice,
      },
      index: params.index,
    });

    history.push(`/order/`);

  }

  const checkRequiredErrors = () => {

    const extrasTypesIdsSelected = selectedExtras.map(filter => filter.type_id);

    // chequeo que haya diferencia entre los ids de extras requeridos y los ids de extras que se seleccionaron

    if (difference(requiredExtraTypes, extrasTypesIdsSelected).length > 0) {
      setExtraTypesWithErrors(difference(requiredExtraTypes, extrasTypesIdsSelected));
      return true;
    }
    else {
      return false;
    }

  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  if (!selectedProd) return null;

  return (
    <div className="container unselectable">
      {/* HEADER */}

      <Close onClick={close} />

      <div className="productPhoto">
        <img
          src={get(selectedProd, 'metadata.src') !== undefined ? get(selectedProd, 'metadata.src') : defaultImage}
          alt={selectedProd.alt}
        />
      </div>

      <div className="whiteBar"></div>

      <div className="info">
        <div className="name">
          {selectedProd.name}
        </div>
        <div className="description">
          {selectedProd.description}
        </div>
        <div className="price">
          ${selectedProd.price}
        </div>
      </div>

      <Divider />

      {/* BODY */}

      {!selectedProd.partial ? (filteredTypes.map((extraType, index) => {
        return (
          <div key={index}>
            <div key={index} className="extraType">

              <div className="info-type"> <div className="name"> Elegí tu {extraType.type_name?extraType.type_name.toLowerCase():extraType.type_name}</div> <div className="cant"> (Max: {extraType.max}) </div> </div>
              {extraType.min > 0 ? (<div className="isRequired">Minimo {extraType.min} </div>) : null}

              {extraTypesWithErrors.includes(extraType.type_id) ? (
                <div style={{ color: "red" }}>* Por favor, seleccione al menos {extraType.min} </div>
              ) : null}
            </div>

            {mapExtras.map((extra, index) => {
              return (
                extraType.type_id === extra.type_id ? (
                  extraType.multiple ? (
                    <div key={index}>
                      <Row key={extra.id} className="extra">
                        <Col xs={{ span: 12 }} md={{ span: 14 }} lg={{ span: 15 }} xl={{ span: 15 }} key={extra.id} className="name" >{extra.name}</Col>
                        <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} xl={{ span: 3 }} key={extra.id} className={extra.price > 0 ? "addPrice" : "none"} ><div> + ${extra.price ? extra.price.toFixed(2) : null} </div></Col>
                        <Col xs={{ span: 7 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} key={extra.id} className="btn" >
                          <div className="quantity-edit" style={{ float: 'right', fontSize: '16px' }} >
                            <div className="pointable" onClick={() => handleMultipleExtra(extra, -1)} > - </div>
                            <div className="unpointable"> {extra.quantity ? extra.quantity : 0} </div>
                            <div className="pointable" onClick={() => handleMultipleExtra(extra, +1)} > + </div>
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                    </div>
                  )
                    :
                    (<div key={index}>
                      <Row key={extra.id} className="extra pointable" onClick={() => handleExtra(extra, extraType)}>
                        <Col xs={{ span: 15 }} md={{ span: 16 }} lg={{ span: 17 }} xl={{ span: 17 }} key={extra.id} className="name" >{extra.name}</Col>
                        <Col xs={{ span: 6 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }} key={extra.id} className={extra.price > 0 ? "addPrice" : "none"} ><div> + ${extra.price ? extra.price.toFixed(2) : null} </div></Col>
                        <Col span={1}></Col>
                        <Col span={2} key={extra.id} className="btn" >
                          {extraType.max === 1 ?
                            selectedExtras.includes(extra) ? (
                              <i className={"sm-radio-checked colored"} />) :
                              (<i className={"sm-radio-unchecked colored"} />) :
                            selectedExtras.includes(extra) ? (
                              <i className={"sm-square-checked colored"} />) :
                              (<i className={"sm-square-unchecked colored"} />)
                          }
                        </Col>
                      </Row>
                      <Divider />
                    </div>)
                ) : null);
            })}
          </div>
        );
      }))
        : <div style={{ textAlign: 'center' }}><Spin indicator={antIcon} /></div>
      }
      <div className="add-product-note">
        <div>Agregá una nota</div>
        <textarea className="text"
          placeholder="Dejanos tu aclaración..."
          value={note}
          onChange={(ev) => setNote(ev.target.value)}
        />
      </div>

      {/* FOOTER */}

      <div className="container-footer-add-product">
        <Row justify="space-around" className="footer-add-product unselectable">
          <Col span={7} className="cant">
            <Row>
              <Col className="pointable" span={8} onClick={quantity > 1 ? (() => handleQuantity(-1)) : null}>-</Col>
              <Col span={8}>{quantity}</Col>
              <Col className="pointable" span={8} onClick={() => handleQuantity(1)}>+</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              disabled={selectedProd.partial}
              className="button-add ignore-disabled-style" size="large">
              <Row>
                <Col span={24}>
                  <Row
                    onClick={
                      params.productId ?
                        () => agregarProducto() :
                        params.index ?
                          () => editarProducto() : null
                    }
                  >
                    <Col style={{ textAlign: 'center' }} span={12}>Agregar</Col>
                    <Col style={{ textAlign: 'center' }} span={12}>${partialPrice.toFixed(2)}</Col>
                  </Row>
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
};

export default ViewEditProduct;
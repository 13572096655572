import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

const Close = ({onClick}) => {
  
  return (
    <div className="closeContainer">
        <div className="close">
            <CloseOutlined onClick={() => onClick()} className="cross pointable" />
        </div>
    </div>
  )
};

export default Close;